import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { AjaxService } from '../services/ajax.service'
import { NavigationEnd, Router } from '@angular/router'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  enableMenu: boolean = false
  username: string = ''
  enableUserMenu: boolean = false
  adminFlag: boolean = false;
  isInAdminPanel: boolean = false;

  constructor(private _userService: UserService, private _router: Router, private _ajaxService: AjaxService) { }

  ngOnInit(): void {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isInAdminPanel = this._router.url.startsWith('/admin');
      }
    });
    this._userService.getUserDetails().subscribe(async (value: any) => {
      if (value != undefined && Object.keys(value).length > 0) {
        this.username = `${value.firstName.substring(0, 1)}${value.lastName.substring(0, 1)}`
        this.enableMenu = true
        this.adminFlag = value.roleId == 5 ? true : false;
        await this.checkUserStatus(value.email)
      } else {
        this.adminFlag = false;
        this.enableMenu = false
      }
    })
    this._userService.getUserMenu().subscribe((value: any) => {
      if (value === true) {
        this.enableUserMenu = true
      } else {
        this.enableUserMenu = false
      }
    })
  }

  checkUserStatus(email : string) {
    const request = {
      "email": email
    }
    this._ajaxService.post("users/login", request).then((response: any) => {
      if (response.statusCode == 401) {
        this.logout();
      }
    });
  }

  /**
   * @author Nagendra
   * @uses To logout user
   */
  logout() {
    this._userService.setUserDetails({})
    this._userService.setUserMenu(false)
    sessionStorage.clear();
    localStorage.clear();
    this._router.navigate(['/login'])
  }

}
