import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Chart, TooltipItem, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { map, startWith } from 'rxjs';
import { AjaxService } from '../services/ajax.service'
import { UserService } from '../services/user.service'
import { Router } from '@angular/router'
import { Align, Anchor } from 'chartjs-plugin-datalabels/types/options';

export interface AcademicYear {
  id : Number;
  year : String;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  enableFilter: boolean = false
  schools = JSON.parse(localStorage.getItem('schools') || '{}')
  searchText = new FormControl()
  filteredSchools: any

  //For Academic Growth Summary
  performance: any = {
    "reading": 0,
    "math": 0
  }

  comparativePerformance : any = {
    1 : { // <--- academic year Id
      "state" : {"EBRW" : 506, "Math" : 495},
      "UTSA" : {"EBRW" : 570, "Math" : 555},
      "Mary" : {"EBRW" : 574, "Math" : 570},
    },
    2 : {
      "state" : {"EBRW" : 506, "Math" : 495},
      "UTSA" : {"EBRW" : 570, "Math" : 555},
      "Mary" : {"EBRW" : 574, "Math" : 570},
    },
    15 : {
      "state" : {"EBRW" : 497, "Math" : 481},
      "UTSA" : {"EBRW" : 570, "Math" : 555},
      "Mary" : {"EBRW" : 574, "Math" : 570},
    },
    3 : {
      "state" : {"EBRW" : 497, "Math" : 481},
      "UTSA" : {"EBRW" : 570, "Math" : 555},
      "Mary" : {"EBRW" : 574, "Math" : 570},
    },
  }

  comparativePerformanceold: any = {
    "state" : {"EBRW" : 506, "Math" : 495},
    "UTSA" : {"EBRW" : 570, "Math" : 555},
    "Mary" : {"EBRW" : 574, "Math" : 570},
  }

  highSchoolPerformanceData: any = {
    "EBRW" : {"9" : 0, "10" : 0, "11" : 0},
    "Math" : {"9" : 0, "10" : 0, "11" : 0},
  }

  //For Academic Performance Summary
  avgPerformance: any = {
    "AvgELA": 0,
    "Avgmath": 0
  }
  catholic: any = {
    "high": 0,
    "moderate": 0,
    "low": 0,
  }
  "enrollment": any = {
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0,
    "2022": 0
  }
  "essentials": any = {
    "overallPerformance": "",
    "effectiveLeaders": "",
    "collaborativeTeachers": "",
    "involvedFamilies": "",
    "supportiveEnvironment": "",
    "ambitiousInstruction": ""
  }
  catholicIdentity: any = {}
  academicFlags: any = {}
  essentialsFlags: any = {}
  enrollmentOperationsFlag: any = {}
  averageEnrollment: number = 0
  performanceChart: any
  performanceChartAvg: any
  catholicChart: any
  highSchoolComparativeChart: any
  highSchoolPerformanceChart: any
  enrollChart: any
  schoolId: number = 0
  flags: any = {
    "academics": 0,
    "prfAcademics": 0,
    "growthAcademics": 0,
    "essentials": 0,
    "catholicIdentity": 0,
    "enrollment": 0
  }
  CIScorecards: string = ''
  blueRibbon: any = {}
  schoolType: string = ''
  isHighSchool: Boolean = false;
  academicYears: AcademicYear[] = [];
  acdemicYearId: number | null = 1;
  labels : any = {
    1 : {"APS" : "Spring 2023", "AGS" : "Fall '22 to Spring '23 Growth"},  // APS - Academic Performance Summary
    2 : {"APS" : "Spring 2024", "AGS" : "Fall '23 to Spring '24 Growth"}, // AGS - Academic Growth Summary
    15 : {"APS" : "Fall 2024", "AGS" : "Fall '23 to Spring '24 Growth"},
    3 : {"APS" : "Fall 2024", "AGS" : "Fall '23 to Spring '24 Growth"},
  }

  @ViewChild('academicChart') academicChart: any;
  @ViewChild('academicChartAvg') academicChartAvg: any;
  @ViewChild('enrollmentChart') enrollmentChart: any;
  @ViewChild('identityChart') identityChart: any;
  @ViewChild('comparativePerformanceChart') comparativePerformanceChart: any;
  @ViewChild('highSchoolPerformace') highSchoolPerformace: any;
  @ViewChild('searchInput') searchInput: ElementRef<any> | undefined

  constructor(private _ajaxService: AjaxService, private _userService: UserService, private _router: Router) {
    this.filteredSchools = this.searchText.valueChanges.pipe(startWith(null), map((text: string | null) => text ? this._filter(text) : this.schools.map((value: any) => ({ ...value }))))
    Chart.register(...registerables);
    Chart.register(ChartDataLabels);
  }

  ngOnInit(): void {
    this.getAcademicYears()
  }

  ngAfterViewInit() {
    if (!this.isHighSchool) {
      this.drawPerformanceChart()
      this.drawPerformanceChartAvg()
    }
    this.drawEnrollChart()
    this.drawCatholicChart()
  }

  //Academic Growth Summary
  drawPerformanceChart() {
    let canvas = this.academicChart.nativeElement;
    let ctx = canvas.getContext('2d');
    this.performanceChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Reading ', 'Math'],
        datasets: [{
          label: '% Progress',
          data: [this.performance.reading, this.performance.math],
          backgroundColor: [
            '#56B1F6',
            '#357FF3',
          ],
          borderColor: [
            '#56B1F6',
            '#357FF3'
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        scales: {
          xAxis: {
            title: {
              display: true,
              text: 'Subject'
            },
            ticks: {
              stepSize: 10,
            },
            grid: {
              display: false,
            }
          },
          y: {
            title: {
              display: true,
              text: '%  Progress'
            },
            ticks: {
              stepSize: 10,
            },
            grid: {
              display: false
            }
          },
        },
        plugins: {
          legend: { display: false },
          datalabels: {
            formatter: (value, ctx) => {
              return value + "%";
            },
            color: 'black',
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          },
          tooltip: {
            enabled: true,
            mode: 'index',
            callbacks: {
              title: (tooltipItems: TooltipItem<'bar'>[]) => {
                const tooltipsLabel = ['Reading 100+ Met', 'Math 100+ Met']
                const index = tooltipItems[0].dataIndex;
                return tooltipsLabel[index];
              }
            }
          }
        }
      }
    });
  }

  //Academic Performance Summary
  drawPerformanceChartAvg() {
    let canvas = this.academicChartAvg.nativeElement;
    let ctx = canvas.getContext('2d');
    this.performanceChartAvg = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Reading', 'Math'],
        datasets: [{
          label: '% Students',
          data: [this.avgPerformance.AvgELA, this.avgPerformance.Avgmath],
          backgroundColor: [
            '#56B1F6',
            '#357FF3',
          ],
          borderColor: [
            '#56B1F6',
            '#357FF3'
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        scales: {
          xAxis: {
            title: {
              display: true,
              text: 'Subject'
            },
            grid: {
              display: false,
            }
          },
          y: {
            title: {
              display: true,
              text: '%  Students'
            },
            ticks: {
              stepSize: 10,
            },
            grid: {
              display: false
            }
          },
        },
        plugins: {
          title: {
            display: true,
            text: 'Students Scoring Avg. or Better on NWEA'
          },
          legend: { display: false },
          datalabels: {
            formatter: (value, ctx) => {
              return value + "%";
            },
            color: 'black',
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        }
      }
    });
  }

  drawEnrollChart() {
    let canvas = this.enrollmentChart.nativeElement;
    let ctx = canvas.getContext('2d');
    let chartLables = []
    try {
      chartLables = Object.keys(this.enrollment)
    } catch {
      chartLables = ['2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023']
    }
    if (chartLables.length > 11) {
      chartLables = chartLables.slice(chartLables.length - 11)
    }
    this.enrollChart = new Chart(ctx, {
      type: 'bar',
      data: {
        // labels: ['FY 2013', 'FY 2014', 'FY 2015', 'FY 2016', 'FY 2017', 'FY 2018', 'FY 2019', 'FY 2020', 'FY 2021', 'FY 2022', 'FY 2023'],
        labels: chartLables,
        datasets: [{
          label: 'Enrollment',
          // data: [this.enrollment['2013'], this.enrollment['2014'], this.enrollment['2015'], this.enrollment['2016'], this.enrollment['2017'], this.enrollment['2018'], this.enrollment['2019'], this.enrollment['2020'], this.enrollment['2021'], this.enrollment['2022'], this.enrollment['2023']],
          data: chartLables.map((key) => this.enrollment[key]),
          backgroundColor: [
            '#87CEEB'
          ],
          borderColor: [
            '#87CEEB'
          ],
          borderWidth: 1
        }]
      },
      options: {
        responsive: true,
        scales: {
          xAxis: {
            grid: {
              display: false,
            }
          },
          y: {
            grid: {
              display: false
            }
          },
        },
        plugins: { legend: { display: false } }
      }
    });
  }

  drawCatholicChart() {
    let canvas = this.identityChart.nativeElement;
    let ctx = canvas.getContext('2d');
    this.catholicChart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: [""],
        datasets: [
        { barPercentage: 0.5,
          label: 'Low',
          backgroundColor: "#FF0F10",
          data: [this.catholic.low],
        }, {
          barPercentage: 0.5,
          label: 'Moderate',
          backgroundColor: "#fdff00",
          data: [this.catholic.moderate],
        }, {
          barPercentage: 0.5,
          label: 'High',
          backgroundColor: "#4fa716",
          data: [this.catholic.high],
        }],
      },
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        indexAxis: 'y',
        scales: {
          xAxis: {
            stacked: true,
            title: {
              display: false,
              text: '% of Students in Score Category'
            },
            ticks: {
              stepSize: 10,
              callback: function(value, index, ticks) {
                return value + '%';
              }
            },
            grid: {
              display: true,
            }
          },
          yAxis: {
            stacked: true,
            grid: {
              display: false,
            }
          }
        },
        plugins: {
          title: {
            display: false,
            text: 'I believe that God is present in my life'
          },
          legend: { display: false },
          datalabels: {
            // formatter: (value, ctx) => {
            //   return value + "%";
            // },
            color: 'black',
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          },
          tooltip: {
            callbacks: {
              label: (Item) => {
                return `${Item.dataset.label}: ${Item.formattedValue}%`
              }
            }
          }
        }
      },
    });
  }

  drawComparativePerformanceChart() {
    const layoutOptions = {
      padding: {
        top: 30,
      },
    };
    let canvas = this.comparativePerformanceChart.nativeElement;
    let ctx = canvas.getContext('2d');
    this.highSchoolComparativeChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['State', 'UTSA', "St.Mary's"],
        datasets: [{
          barPercentage: 0.7,
          label: 'EBRW',
          backgroundColor: ["#56B1F6"],
          data: [this.comparativePerformance[this.acdemicYearId || 1].state.EBRW, this.comparativePerformance[this.acdemicYearId || 1].UTSA.EBRW, this.comparativePerformance[this.acdemicYearId || 1].Mary.EBRW],
        }, {
          barPercentage: 0.7,
          label: 'Math',
          backgroundColor: ["#0c4b78"],
          data: [this.comparativePerformance[this.acdemicYearId || 1].state.Math, this.comparativePerformance[this.acdemicYearId || 1].UTSA.Math, this.comparativePerformance[this.acdemicYearId || 1].Mary.Math],
        }]
      },
      options: {
        layout: layoutOptions,
        responsive: true,
        scales: {
          xAxis: {
            title: {
              display: false,
            },
            grid: {
              display: false,
            }
          },
          y: {
            min: 300,
            title: {
              display: false,
              text: ''
            },
            ticks: {
              stepSize: 50,
            },
            grid: {
              display: false,
              color: (context: any) => {
                const Zero = context.tick.value;
                const res = Zero === 0 ? '#666' : '#ffffff';
                return res
              }
            }
          },
        },
        plugins: {
          legend: { display: true,
            position : 'right',
            labels : {
              boxWidth : 10,
              padding : 10
            } 
          },
          datalabels: {
            formatter: (value, ctx) => {
              return value;
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            padding: {
              top: 1,
            },
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          },
          tooltip: {
            callbacks: {
              label: (Item) => {
                return `${Item.dataset.label}: ${Item.formattedValue}`
              }
            }
          }
        }
      }
    });
  }

  drawHighSchoolPerformanceChart() {
    const layoutOptions = {
      padding: {
        top: 30,
      },
    };
    let canvas = this.highSchoolPerformace.nativeElement;
    let ctx = canvas.getContext('2d');
    this.highSchoolPerformanceChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['EBRW', 'Math'],
        datasets: [{
          barPercentage: 0.7,
          backgroundColor: ["#b0c5d4"],
          label: '9th Grade',
          data: [this.highSchoolPerformanceData.EBRW['9'], this.highSchoolPerformanceData.Math['9']],
        }, {
          barPercentage: 0.7,
          backgroundColor: ["#56B1F6"],
          label: '10th Grade',
          data: [this.highSchoolPerformanceData.EBRW['10'], this.highSchoolPerformanceData.Math['10']],
        }, {
          barPercentage: 0.7,
          backgroundColor: ["#0c4b78"],
          label: '11th Grade',
          data: [this.highSchoolPerformanceData.EBRW['11'], this.highSchoolPerformanceData.Math['11']],
        }]
      },
      options: {
        layout: layoutOptions,
        responsive: true,
        scales: {
          xAxis: {
            title: {
              display: false,
            },
            grid: {
              display: false,
            }
          },
          y: {
            min: 300,
            title: {
              display: false,
              text: ''
            },
            ticks: {
              stepSize: 50,
            },
            grid: {
              display: false,
              color: (context: any) => {
                const Zero = context.tick.value;
                const res = Zero === 0 ? '#666' : '#ffffff';
                return res
              }
            }
          },
        },
        plugins: {
          legend: { display: true,
            position : 'right',
            labels : {
              boxWidth : 10,
              padding : 10
            } 
          },
          datalabels: {
            formatter: (value, ctx) => {
              return value;
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            padding: {
              top: 1,
            },
            labels: {
              title: {
                font: {
                  weight: 'bold'
                }
              }
            }
          },
          tooltip: {
            callbacks: {
              label: (Item) => {
                return `${Item.dataset.label}: ${Item.formattedValue}`
              }
            }
          }
          // tooltip: {
          //   enabled: true,
          //   mode: 'index',
          //   callbacks: {
          //     // title: (tooltipItems: any) => {
          //     //   const tooltipsLabel = ['Reading', 'Avg. Reading', 'Math', 'Avg. Math']
          //     //   let index = 0;
          //     //   const label = tooltipItems[0].label;
          //     //   if (label == 'Math') {
          //     //     if (tooltipItems[0].element.active) {
          //     //       index = 2
          //     //     } else {
          //     //       index = 3
          //     //     }
          //     //   } else {
          //     //     if (tooltipItems[0].element.active) {
          //     //       index = 0
          //     //     } else {
          //     //       index = 1
          //     //     }
          //     //   }
          //     //   return tooltipsLabel[index];
          //     // },
          //     label: (Item) => {
          //       let value;
          //       if (Item.element.active) {
          //         value = Item.formattedValue
          //         return `${value}`
          //       } else {
          //         return ''
          //       }
          //     }
          //   }
          // }
        }
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = String(value).toLowerCase();
    return this.schools.filter((school: any) => ((school.name).toLowerCase()).includes(filterValue));
  }

  selectSchool(school: any) {
    if (school != undefined || school != null) {
      this.isHighSchool = school.schoolType === 'HIGH' ? true : false;
      this.schoolType = school.schoolType
      this.searchText.setValue(school.name)
      this.schoolId = school.schoolId
      this._userService.setSchoolId(this.schoolId);
      school.users === true ? this._userService.setUserMenu(true) : this._userService.setUserMenu(false)
      this.closeOptions()
      this.loadWorkbook()
    }
  }

  closeOptions() {
    this.enableFilter = false
    this.searchInput?.nativeElement.blur()
  }

  loadWorkbook() {
    this.performance.reading = 0;
    this.performance.math = 0;
    this.avgPerformance.AvgELA = 0;
    this.avgPerformance.Avgmath = 0;
    this.averageEnrollment = 0
    this.essentials = {}
    this.academicFlags = {}
    this.essentialsFlags = {}
    this.catholicIdentity = {}
    this.enrollmentOperationsFlag = {}
    this.flags = {
      "academics": 0,
      "prfAcademics": 0,
      "growthAcademics": 0,
      "essentials": 0,
      "catholicIdentity": 0,
      "enrollment": 0
    }
    this.CIScorecards = ''
    this.blueRibbon = {}
    const request = { "schoolId": this.schoolId, "isHighSchool": this.isHighSchool ? 1 : 0, acdemicYearId : this.acdemicYearId}
    this._ajaxService.post("dashboard/loadWorkbook", request).then((response: any) => {
      if (response.statusCode == 200) {
        if (Object.keys(response.data).length > 0) {
          const info = response.data
          if (!this.isHighSchool) {
            //Academic Growth Summary
            this.performance.reading = Number(info['performance']['reading']);
            this.performance.math = Number(info['performance']['math']);
            if (this.performanceChart) this.performanceChart.destroy();
            this.drawPerformanceChart()
            //Academic Performance Summary
            this.avgPerformance.AvgELA = Number(info['performance']['ELAAvgOrBetter']);
            this.avgPerformance.Avgmath = Number(info['performance']['MathAvgOrBetter']);
            if (this.performanceChartAvg) this.performanceChartAvg.destroy();
            this.drawPerformanceChartAvg()
          }
          //Catholic Identity
          this.catholic.high = Number(info['catholic']['high'])
          this.catholic.moderate = Number(info['catholic']['moderate'])
          this.catholic.low = Number(info['catholic']['low'])
          this.catholicChart.destroy();
          this.drawCatholicChart()
          //Enrollment
          this.enrollment = info['enrollment']
          this.enrollChart.destroy();
          this.drawEnrollChart();
          //Essentials
          this.averageEnrollment = info['avgEnrollment']
          this.averageEnrollment = this.averageEnrollment ? Math.round(this.averageEnrollment) : this.averageEnrollment;
          this.essentials = info['essentials']
          //Flag Notifications
          this.academicFlags = info['academicFlags']
          this.essentialsFlags = info['essentialsFlags']
          this.catholicIdentity = info['catholicIdentity']
          this.enrollmentOperationsFlag = info['enrollmentOperationsFlag']
          //Enable green/red mark based on flag 'Y'
          Object.keys(this.academicFlags).forEach((key, value) => {
            if (this.academicFlags[key] == 'Y') {
              this.flags['academics']++
              if (key == '<50% of students are scoring Avg. or Better ELA' || key == '<50% of students are scoring Avg. or Better Math') {
                this.flags['prfAcademics']++
              } else {
                this.flags['growthAcademics']++
              }
            }
          })
          Object.keys(this.essentialsFlags).forEach((key, value) => {
            if (this.essentialsFlags[key] == 'Y') {
              this.flags['essentials']++
            }
          })
          Object.keys(this.catholicIdentity).forEach((key, value) => {
            if (this.catholicIdentity[key] == 'Y') {
              this.flags['catholicIdentity']++
            }
          })
          Object.keys(this.enrollmentOperationsFlag).forEach((key, value) => {
            if (this.enrollmentOperationsFlag[key] == 'Y') {
              this.flags['enrollment']++
            }
          })
          // this.CIScorecards = info['CIScorecards'];
          this.blueRibbon = {
            "year": info.blueRibbon['year'],
            "Spring": info.blueRibbon['Spring'],
            "Fall": info.blueRibbon['Fall']
          }
          if (this.isHighSchool) {
            if (this.highSchoolComparativeChart) this.highSchoolComparativeChart.destroy();
            this.drawComparativePerformanceChart();
            // this.highSchoolComparativeChart.destroy();
            this.highSchoolPerformanceData.EBRW['9'] = isNaN(Number(info?.['performance']?.['9']?.['EBRW'])) ? 0 : Number(info?.['performance']?.['9']?.['EBRW']);
            this.highSchoolPerformanceData.EBRW['10'] = isNaN(Number(info?.['performance']?.['10']?.['EBRW'])) ? 0 : Number(info?.['performance']?.['10']?.['EBRW']);
            this.highSchoolPerformanceData.EBRW['11'] = isNaN(Number(info?.['performance']?.['11']?.['EBRW'])) ? 0 : Number(info?.['performance']?.['11']?.['EBRW']);
            this.highSchoolPerformanceData.Math['9'] = isNaN(Number(info?.['performance']?.['9']?.['Math'])) ? 0 : Number(info?.['performance']?.['9']?.['Math']);
            this.highSchoolPerformanceData.Math['10'] = isNaN(Number(info?.['performance']?.['10']?.['Math'])) ? 0 : Number(info?.['performance']?.['10']?.['Math']);
            this.highSchoolPerformanceData.Math['11'] = isNaN(Number(info?.['performance']?.['11']?.['Math'])) ? 0 : Number(info?.['performance']?.['11']?.['Math']);
            if (this.highSchoolPerformanceChart) this.highSchoolPerformanceChart.destroy();
            this.drawHighSchoolPerformanceChart();
          }
        } else {
          this.performanceChart.destroy();
          this.drawPerformanceChart()
          this.performanceChartAvg.destroy();
          this.drawPerformanceChartAvg()
          this.catholicChart.destroy();
          this.drawCatholicChart()
          this.enrollChart.destroy();
          this.drawEnrollChart();
          this.flags = {
            "academics": 0,
            "essentials": 0,
            "catholicIdentity": 0,
            "enrollment": 0
          }
          this.CIScorecards = ''
          this.blueRibbon = {}
        }
      }
    })
  }

  getSchoolsList() {
    this._userService.getUserDetails().subscribe((value: any) => {
      if (value != undefined && Object.keys(value).length > 0) {
        const request = {
          "email": value.email
        }
        this._ajaxService.post("users/login", request).then((response: any) => {
          if (response.statusCode == 200) {
            localStorage.setItem('schools', JSON.stringify(response.data['schools']))
          }
        });
      }
    })
  }

  async getAcademicYears() {
    this._ajaxService.get("dashboard/getAcademicYears").then(async (response: any) => {
      this.academicYears = response;
      const idx = response.length;
      if (this._userService.getAcademicYearId().value != null) {
        this.acdemicYearId = this._userService.getAcademicYearId().value
      } else {
        this.acdemicYearId = response[idx - 1]['id']
        this._userService.setAcademicYearId(response[idx - 1]['id'])
      }
      await this.getSchoolsList();
      if (this._userService.getSchoolId().value != null) {
        let index = this.schools.findIndex((school: any) => school.schoolId == this._userService.getSchoolId().value);
        this.selectSchool(this.schools[index])
      } else {
        this.selectSchool(this.schools[0]);
      };
  
    });
  }

  selectAcademicYearId(e : any) {
    if (e) {
      this.acdemicYearId = parseInt(e.target.value);
      this._userService.setAcademicYearId(e.target.value)
      this.loadWorkbook();
    }
  }
}
